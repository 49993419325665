<template>
  <div style="margin: 10px">
    <form>
      <div class="row">
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="fechaExtravio" class="form-label" aria-label="fechaExtravio">
            Fecha del Extravio *</label
          >
          <input
            type="date"
            id="fechaExtravio"
            class="form-control"
            :class="{ error: $v.form.fechaExtravio.$error }"
            v-model.trim="$v.form.fechaExtravio.$model"
            aria-label="Fecha Del Expediente"
          />
        </div>

      </div>
    </form>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { required} from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: ["clickedNext", "currentStep"],
  name: "GeneralForm",
  mounted() {
    // this.getStates();
    /* console.log("mounted", this.$v.$invalid); */
    this.setMunicipios([]);
    if (!this.$v.$invalid) {
      this.$emit("can-continue", { value: true });
    } else {
      // this.verTerminos();
      this.$emit("can-continue", { value: false });
    }
  },
  activated: function () {
    /* console.log("activate"); */
    if (!this.$v.form.$invalid) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  data() {
    return {
      form: {
        fechaExtravio: "",
      },
    };
  },
  validations() {
    return {
      form: {
        fechaExtravio:{
          required
        }
      },
    };
  },
  computed: {
    ...mapState("generals", [
      "tipoDocData",
      "generosData",
      "ciudades",
      "estados",
    ]),
    estadoSeleccionado() {
      return this.form.estado;
    },
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
    ...mapMutations("generals", ["setMunicipios"]),
    ...mapActions({
      getStates: "generals/getStates",
      getMunicipios: "generals/getMunicipios",
    }),
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (!val.$invalid) {
          this.$emit("can-continue", { value: true });
          this.setFormatoDatos(this.form);
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
        // this.setFormatoDatos(this.form)
      }
    },
    estadoSeleccionado: {
      handler: function (val) {
        if (val) {
          this.form.ciudad = null;
          console.log(val.id);
          this.getMunicipios(val.id - 1);
        }
      },
    },
  },
};
</script>
<style>
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.vs__dropdown-toggle {
  border: 1px solid #eee;
  height: calc(1.5em + 0.75rem + 2px);
}
.v-select {
  height: calc(1.5em + 0.75rem + 2px);
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>